import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Card,
  message,
  Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  FileInputSingle,
  GroupPicker,
  AttendeePicker,
  SendNotificationInput,
  ModulePicker,
  ItemByNotifModulePicker,
  LangPicker,
  PinNotificationInput,
  SendNowNotificationInput,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const PushNotificationDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let [moduleChange, setModuleChange] = useState(false);
  let id = params.id !== 'add' ? params.id : false;
  const { TextArea } = Input;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCode] = useGlobal('langCode');
  let [langCms] = useGlobal('langCms');

  let newRecord = {
    lang: langCode ? langCode : langCms[0].code,
    title: '',
    content: '',
    active: true,
    date: new Date(),
    groups: [],
    selectedAttendees: [],
    closeDetail:false
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let copy = props.location.pathname.split('/')[2];

  console.log("path   ",copy)

  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/pushNotifications/' + id)
        .then(({ data: { result, result_message } }) => {
          if(copy=="copy"){
            result.completed=false
            delete result._id
          }
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang === null) errors.lang = languagesCms.ENFORCED;

    if (data.title === null || data.title.length === 0)
      errors.title = languagesCms.ENFORCED;

    if (data.content === null || data.content.length === 0)
      errors.content = languagesCms.ENFORCED;

    if (data.date === null) errors.date = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);
console.log("Data   ",data)
let save = async () => {
  setValidationCheck(true);
  let err = validate();
  if (err.hasError) {
    setErrors(err);
    window.scrollTo({ top: 20, behavior: 'smooth' });
    return; 
  }

  setLoading(true); 

  try {
    if (copy === "copy") {
      delete data._id;
      const response = await api.post('/rest/pushNotifications', data);
      const { result_message } = response.data;
      if (result_message.type === 'success') {
        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
        history.push('/pushNotifications');
      } else {
        message.error(result_message.message, 2);
      }
    } else if (id) {
      const response = await api.put('/rest/pushNotifications/' + id, data);
      const { result_message } = response.data;
      if (result_message.type === 'success') {
        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
        history.push('/pushNotifications');
      } else {
        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      }
    } else {
      const response = await api.post('/rest/pushNotifications', data);
      const { result_message } = response.data;
      if (result_message.type === 'success') {
        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
        history.push('/pushNotifications');
      } else {
        message.error(result_message.message, 2);
      }
    }
  } catch (error) {
    message.error(languagesCms.ERROR_SAVE_RECORD, 2);
  } finally {
    setLoading(false); 
  }
};

  console.log("data.type   ",data.type)
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/pushNotifications">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker
                    langCms={langCms}
                    langCode={langCode}
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="lang"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item
                  label={languagesCms.GROUP}
                  help={errors.groups}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item
                  label={languagesCms.USERS}
                  help={errors.selectedAttendees}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <AttendeePicker
                    record={data}
                    setRecord={setData}
                    name="selectedAttendees"
                    languagesCms={languagesCms}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SHOW_APP_LIST}
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SENDED_TIME}
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.date)}
                    onChange={(v) => setData({ ...data, date: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SEND_NOTIFICATION_NOW}
                  help={errors.sendNotification}
                  validateStatus={errors.sendNotification ? 'error' : 'success'}
                >
                  <SendNotificationInput
                    record={data}
                    setRecord={setData}
                    name="sendNotification"
                    languagesCms={languagesCms}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SEND_NOW}
                  help={errors.sendNow}
                  validateStatus={errors.sendNow ? 'error' : 'success'}
                >
                  <SendNowNotificationInput
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="sendNow"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.FIXED_NOTIFICATION}
                  help={errors.pin}
                  validateStatus={errors.pin ? 'error' : 'success'}
                >
                  <PinNotificationInput
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="pin"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms ? languagesCms.NOTIFICATION_DETAIL_BUTTON_ISOPEN : ''}
                  help={errors.closeDetail}
                  validateStatus={errors.closeDetail ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.closeDetail ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, closeDetail: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : 'success'}
                >
                  <Input
                    name="title"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.CONTENTS}
                  required
                  help={errors.content}
                  validateStatus={errors.content ? 'error' : 'success'}
                >
                  <TextArea
                    name="content"
                    value={data.content}
                    onChange={(e) =>
                      setData({ ...data, content: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.OPEN_MODULE}
                  required
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <ModulePicker
                    isNotificationPage={true}
                    languagesCms={languagesCms}
                    langCode={langCode}
                    langCms={langCms}
                    record={data} 
                    setRecord={setData} 
                    moduleChange={moduleChange} 
                    setModuleChange={setModuleChange}
                    name="type"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.SHARING} required help={errors.item_id} validateStatus={errors.type ? 'error' : 'success'}>
                  <ItemByNotifModulePicker languagesCms={languagesCms} record={data} setRecord={setData} name="item_id" moduleChange={moduleChange}
                    disabled={data.type === "not_modul" || data.type === "notifications" || !data.type ? true : false} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <Alert message={languagesCms.PUSH_MODULE_ALERT_MESSAGE} banner />
            </Col>
            </Row>
            <Row direction="row">
              <Col span={12}>
                <Form.Item
                  label="İcon"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="icon"
                  />
                  <Alert message={languagesCms.ICON_SIZE_ALERT} banner />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label={languagesCms.MEDIA}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="media"
                  />
                  <Alert
                    message={languagesCms.NOTIFICATION_MEDIA_SIZE}
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default PushNotificationDetail;
