import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row,Col,Form, Button,Card, message, Switch,InputNumber, Alert} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MultipleLangPickerApprove} from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import { ChromePicker } from 'react-color';
import rgbHex from 'rgb-hex';


const UserKVKKDetail = (props) => {
  let params = useParams()
  let history = useHistory()
  let id=params.id !== "add" ? params.id : false;

  let [modules] = useGlobal("modules");
  let [langCode] = useGlobal("langCode");
  let [langCms] = useGlobal("langCms");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }
  
  let newRecord = {
    langs: [{ lang: langCode ? langCode : langCms[0].code, title: '',pdf:null }],
    order: 0,

    active:true
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false)

    useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])

  let get = async () => {
    if(id) {
      await api.get("/rest/userKVKK/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {

      setLoading(false)
    }
  }

  let validate = useCallback(() => {
    let errors = {};

   
   
    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else{
      if(id) {
        api.put("/rest/userKVKK/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/userKVKK')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        if (typeof data.thumb === "object") {
          data.thumb = data.thumb.thumb
        }
        api.post("/rest/userKVKK", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/userKVKK')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }  
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/userKVKK"}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <MultipleLangPickerApprove
                  langCms={langCms}
                  langCode={langCode}
                  title={'title'}
                  record={data}
                  setRecord={setData}
                  name="lang"
                  languagesCms={languagesCms}
                />
              </Col>
            </Row>
            <Row direction="row">
   
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.ORDER} help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                  <InputNumber value={data.order} min={0} defaultValue={0} style={{ width: 100 }} onChange={v => setData({ ...data, order: v })} />
                </Form.Item>
              </Col>


            </Row>
            <Row direction="row">

            {data.order==1 &&
               <Col xs={{ span: 24 }} md={{ span: 24 }}>
               <Alert message={"KVKK Metni"} banner />
               </Col>

            }

            {data.order==2 &&
               <Col xs={{ span: 24 }} md={{ span: 24 }}>
               <Alert message={"Kullanım Koşulları"} banner />
               </Col>

            }
             </Row>

            <Row>


            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};

export default UserKVKKDetail;
